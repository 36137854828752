.App {
  text-align: center;

  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}

button {
  width: 100px;
  background-color: aqua;
  transition: background-color 0.2s;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

button:hover {
  background-color: pink;
}
